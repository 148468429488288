


















































































































































































import vueFilePond, { VueFilePondComponent } from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import WidgetMixins from '../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { currencyFormat, loadWidget, toBase64 } from '@/utils/helpers';
import { namespace } from 'vuex-class';
import { IProduct } from '@/types/types';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { image, numeric, required } from 'vee-validate/dist/rules';
import { VueEditor } from 'vue2-editor';
// Import the plugin code
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
import { FilePondFile } from 'filepond';

extend('required', {
  ...required,
  message: 'Field is required',
});
extend('numeric', {
  ...numeric,
  message: 'Invalid amount provided',
});
extend('image', {
  ...image,
  message: 'Input must be an image',
});
const FilePond = vueFilePond(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginImageCrop,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode,
  FilePondPluginImageTransform,
  FilePondPluginFileMetadata,
);
const productsModule = namespace('products');
@Component({
  name: 'Create',
  components: {
    FilePond,
    VueEditor,
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    InternetConnection: loadWidget('pages/InternetConnection'),
    Notifications: loadWidget('pages/Notifications'),
    ValidationObserver,
    ValidationProvider,
    ProgressLoader: loadWidget('widgets/CircularProgressLoader'),
  },
  filters: {
    currencyFormat,
  },
})
export default class Create extends mixins(WidgetMixins) {
  @productsModule.Getter('getAllProducts') products!: IProduct[];

  search: string = '';
  toolbar: Array<Array<string | object>> = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    ['link', 'code-block', 'blockquote'],
  ];

  rules: any = [(value: any) => !value || value.size < 1000000 || 'Avatar size should be less than 1 MB!'];
  myFile: [] = [];
  product: Pick<IProduct, 'name' | 'description' | 'url' | 'logo' | "appSubPath"> = {
    name: '',
    url: '',
    logo: '',
    description: '',
    appSubPath: ''
  };
  images: File[] = [];

  getFile() {}

  get filteredProducts() {
    return this.products.filter((product: IProduct) => {
      return product.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
    });
  }

  addProduct() {
    if (this.product.description === '') {
      this.$store.dispatch('snackBarMessage', 'Product description is a required field', {
        root: true,
      });
      this.$store.dispatch('snackBarVisibility', true, { root: true });
    } else {
      let file = this.$refs.pond as VueFilePondComponent;
      let typedFiles = file?.getFiles() as Array<FilePondFile & { getFileEncodeBase64String: () => string }>;
      if (typedFiles.length > 0) {
        this.$store.dispatch('products/create', {
          ...this.product,
          logo: typedFiles[0].getFileEncodeBase64String(),
        });
      } else {
        this.$store.dispatch('snackBarMessage', 'Product images is required', {
          root: true,
        });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      }
    }
  }
  created(): void {
    this.$store.dispatch('products/list');
  }
}
