var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"institution-pane d-flex flex-column flex-grow-1 flex-shrink-1 flex-wrap"}},[_c('div',{staticClass:"d-flex flex-row  flex-grow-1 flex-wrap flex-shrink-1"},[_c('div',{staticClass:"d-flex flex-column mid-container"},[_c('div',{staticClass:"dash-side-container dash-side-container-topNavbar"},[_c('ToolBar',{attrs:{"title":_vm.$route.meta.pageTitle,"showBackButton":true,"backUrl":_vm.pageLoading ? '' : 'products'}})],1),(!_vm.internetState)?_c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"width":"100% !important"}},[(_vm.pageLoading)?_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1 flex-wrap"},[_c('ProgressLoader',{style:({ height: '90vh', paddingTop: '20px' }),attrs:{"size":30}})],1):_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1 mb-12"},[_c('ValidationObserver',{staticClass:"d-flex flex-column flex-fill flex-shrink-1",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addProduct)}}},[_c('v-card',{staticClass:"ma-3",attrs:{"elevation":"0"}},[_c('v-card-text',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-0",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('v-text-field',{staticClass:"ft text-sm",attrs:{"outlined":"","label":"Product Name","error-messages":errors[0],"hint":"Add product name","autofocus":""},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}})],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-0",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('v-text-field',{staticClass:"ft text-sm",attrs:{"outlined":"","label":"URL","placeholder":"eg. https://example.com","error-messages":errors[0],"hint":"Add product url"},model:{value:(_vm.product.url),callback:function ($$v) {_vm.$set(_vm.product, "url", $$v)},expression:"product.url"}})],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-0",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('v-text-field',{staticClass:"ft text-sm",attrs:{"outlined":"","label":"App Sub Path","placeholder":"eg. /sms","error-messages":errors[0],"hint":"Add product sub path"},model:{value:(_vm.product.appSubPath),callback:function ($$v) {_vm.$set(_vm.product, "appSubPath", $$v)},expression:"product.appSubPath"}})],1)]}}],null,true)}),_c('div',{staticClass:"d-flex flex-column flex-shrink-1 flex-wrap"},[_c('VueEditor',{attrs:{"editorToolbar":_vm.toolbar},model:{value:(_vm.product.description),callback:function ($$v) {_vm.$set(_vm.product, "description", $$v)},expression:"product.description"}})],1),_c('div',{staticStyle:{"width":"100%"}},[_c('span',{staticClass:"d-flex flex-grow-1 flex-row justify-center font-weight-semi-bold text-md text-center ft",staticStyle:{"margin-bottom":"-16px","margin-top":"18px"}},[_vm._v("Upload product logo")]),_c('file-pond',{ref:"pond",staticClass:"ft font-weight-medium text-sm mt-6",attrs:{"name":"test","allowImageCrop":"true","label-idle":"Drag & Drop Product Logo Here <br/> <span class='ft text-capitalize filepond--label-action'>Browse</span>","allow-multiple":true,"styleLoadIndicatorPosition":"center bottom","styleProgressIndicatorPosition":"right bottom","styleButtonProcessItemPosition":"right bottom","styleButtonRemoveItemPositon":"right bottom","maxFileSize":"1MB","allowFileMetadata":true,"labelMaxFileSizeExceeded":"Maximum file size is 1MB","labelFileTypeNotAllowed":"File type not supported","acceptedFileTypes":['image/jpeg', 'image/png', 'image/jpg', 'image/webp'],"instantUpload":"false","allowFileSizeValidation":true,"allowFileEncode":true,"allowImageTransform":true,"imageTransformOutputQuality":60,"maxFiles":"1","files":_vm.myFile,"allow-file-type-validation":true,"imageResizeTargetWidth":300,"fileMetadataObject":{
                        lord: 'banks',
                      }},on:{"init":_vm.getFile}})],1)],1),_c('v-card-actions',{staticClass:"mx-2 mb-11"},[_c('v-btn',{staticClass:"ft font-weight-medium",attrs:{"type":"submit","block":"","disabled":_vm.isLoading,"loading":_vm.isLoading,"color":"accent"}},[_vm._v(" Save Product ")])],1)],1)],1)]}}],null,false,3697594288)})],1)]):_c('InternetConnection',{on:{"refreshPage":function($event){return _vm.$store.dispatch('products/list')}}})],1),_c('div',{staticClass:"messaging-sidebar px-2  ",class:[!_vm.pageLoading ? 'details-sidebar--drawer-bg ' : '']},[(_vm.pageLoading)?_c('SkeletonPreloader',{staticClass:"mt-4",attrs:{"count":6,"width":'100%'}}):(_vm.products.length > 0)?_c('div',{staticClass:"d-flex flex-column flex-fill flex-grow-1 mt-3"},[_c('div',{staticClass:"v--shadow-light title dash-side-container dash-side-container-sideColumn"},[_c('h3',{staticClass:"ft font-weight-medium text-md ml-1"},[_vm._v("Products")])]),_c('v-card',{staticStyle:{"border-radius":"0 !important"}},[_c('v-text-field',{staticClass:"ft font-weight-medium text-sm mx-1 mt-1 pb-1 d-flex flex-row vx-shadow ",staticStyle:{"flex":"0 1 auto"},attrs:{"filled":"","dense":"","prepend-inner-icon":"search","hide-details":"","flat":"","solo":"","placeholder":"Search through products ..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card',{staticClass:"v--shadow-light"},_vm._l((_vm.filteredProducts),function(product){return _c('Notifications',{key:product.id,attrs:{"title":product.name,"subTitle":product.url}})}),1)],1):_c('div',{staticClass:"d-flex flex-column flex-fill flex-grow-1"},[_c('EmptyPage',{attrs:{"image":require('@/assets/images/clip-reading-of-books-1.png'),"title":'Nåda!',"subTitle":'Added products will show up here',"imageWidth":'210px'}})],1)],1),_c('SnackBar',{attrs:{"snackbar":_vm.showSnackBar,"text":_vm.message,"timeout":_vm.timeout}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }